import { UnleashClient } from "@unleash/proxy-client-react";
import { getConfig } from "@telia/b2b-unleash-client-config";
import { useEffect, useState } from "react";

interface ReactUnleashClient {
  client: UnleashClient | undefined;
}

export function useUnleashClientWithScopeId(appName: string): ReactUnleashClient {
  const [client, setClient] = useState<UnleashClient | undefined>();

  useEffect(() => {
    const loadUnleashClient = async () => {
      const config = await getConfig(appName);
      const unleashClient: UnleashClient = new UnleashClient(config);
      setClient(unleashClient);
      await unleashClient.start();
    };
    loadUnleashClient();
  }, []);

  return { client };
}
